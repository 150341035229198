import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material'; 
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import axios from '../../../utils/axios';
import MyLogo from '../../../components/userlogo';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {


  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState([]);

  const totalUnRead = notifications?.filter((item) => item?.status === 0).length;

  const [open, setOpen] = useState(null);
 const AccessToken =  window.localStorage.getItem('accessToken');
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  }; 
  
  useEffect(() => {
    axios.get(`/api/system/notification/user/${AccessToken}/request/user`).then(data => {
       setNotifications(data.data.notif);
 
    });
  }, [AccessToken]);
  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    await axios(`/api/clear/notification/clear/all/${AccessToken}/by/user`).then(
      setNotifications(
        notifications?.map((notification) => ({
          ...notification,
          status: 1,
        }))
      ),
      enqueueSnackbar('Marked Read Successfully')
    );
 
  };

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications?.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications?.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id}  notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple
           component={RouterLink}
           to={PATH_DASHBOARD.general.notif}
           onClick={handleClose}
          >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string,
    status: PropTypes.number,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
    profile_image: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const {  title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification?.status===0 && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
      <MyLogo username={notification?.profile_image}/>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {notification?.date}
          </Typography>
        }
      />
    </ListItemButton>
  );
}


// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      SYSTEM NOTIFICATION
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification?.message)}
      </Typography>
    </Typography>
  );

    return {
      title,
    };
}
