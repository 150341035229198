import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 225.000000 225.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)">
          <path d="M1606 1825 c-131 -67 -274 -185 -359 -296 l-27 -36 68 -205 68 -205
51 133 52 132 -30 98 c-16 53 -29 99 -29 101 0 1 22 3 48 3 l49 0 31 -82 c17
-46 51 -141 75 -213 25 -71 48 -138 53 -149 5 -13 13 2 30 60 12 44 47 147 76
229 l54 150 57 3 56 3 -23 -68 c-13 -37 -45 -126 -71 -198 -26 -71 -74 -208
-107 -303 -34 -95 -64 -169 -68 -165 -4 4 -38 92 -75 196 -38 103 -71 187 -74
187 -3 0 -39 -84 -80 -188 -40 -103 -77 -191 -81 -195 -4 -4 -51 118 -106 272
-66 186 -102 274 -107 265 -5 -8 -3 -30 3 -49 12 -37 15 -166 4 -211 -11 -48
-65 -143 -96 -169 -27 -22 -28 -28 -28 -105 0 -178 75 -414 180 -570 l16 -23
84 17 c348 71 618 329 706 673 19 75 24 121 24 215 0 197 -43 350 -141 503
-51 80 -183 225 -204 225 -6 0 -42 -16 -79 -35z"/>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
