import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from '../routes/paths';
// components
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized} = useAuth();
  if (!isInitialized) {
    return <LoadingScreen />;
  }
  if(isAuthenticated === true){
    return <Navigate to={PATH_DASHBOARD.root} />;
  }
 if(isAuthenticated !== 'verify'){
  return <Container>
  <Alert severity="error">
    <AlertTitle>Permission Denied</AlertTitle>
    You do not have permission to access this page
  </Alert>
</Container>
 }

  return <>{children}</>;
}

