import PropTypes from 'prop-types';
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

MyLogo.propTypes = {
    username: PropTypes.any,
}
export default function MyLogo({ username }) {
 

  return (
    <Avatar
      src={username}
      alt={username}
      color={username ? 'default' : createAvatar(username).color}
    >
      {createAvatar(username).name}
    </Avatar>
  );
}
